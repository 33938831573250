:root {
  --color--black: #000;
  --color--white: #fff;
  --color--athens-gray: #F0F2F5;
  --color--gallery: #f0f0f0;
  --color--alto: #d9d9d9;
  --color--green-vogue: #011951;
  --color--lima: #52c41a;

  --color--app--background: var(--color--athens-gray);
  --color--app--contentcontainer-background: var(--color--white);
  --color--app--portabletextinput-border: var(--color--alto);
  --color--app--imagepointselectorinput-spot-border: var(--color--white);
  --color--app--imagepointselectorinput-spot-background: var(--color--green-vogue);
  --color--app--scheduledsessionpagecontent-loading-background: rgba(255, 255, 255, .8);
  --color--app--scheduledsessiondashboardparticipantinfo-checked-icon-fill: var(--color--lima);
  --color--app--sortablelistinput-item-preview-background-even: var(--color--white);
  --color--app--sortablelistinput-item-preview-background-odd: var(--color--athens-gray);
  --color--app--box-background: var(--color--white);
  --color--app--box-shadow: rgba(0, 0, 0, .1);

  --color--print--contentcontainer-border: var(--color--athens-gray);
}
