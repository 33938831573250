@import "./vars/colors";
@import "./nprogress";

html, body, #__next {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  min-height: 100%;
  flex-direction: column;
  background-color: var(--color--app--background);
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
}

@media print {
  #__next {
    display: none;
  }
}